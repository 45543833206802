
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { addOfficialSystem, updateOfficialSystem, delOfficialSystem, getOfficialSystemList } from "@/api/request/bulletin";

//组件
@Component({
  name: "SystemNotify",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //请求数据
    country: "", //归属国家
  };

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getOfficialSystemList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //初始化数据
    this.form = {
      //表单数据
      type: 0, //发送
      title: "", //标题
      content: "", //内容
      country: "MY", //归属国家
      uids: [], //用户ID列表
      sys_type: 0, //消息类型
      is_push: 0, //是否推送
      id: undefined, //ID
    };
    this.tags = [];
    this.tagInput = "";
    this.tagInputMore = "";

    //隐藏界面
    this.dialogShow = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //初始化数据
    this.form = {
      //表单数据
      id: row.id, //ID
      type: row.type, //发送
      uids: row.uids, //用户ID
      title: row.title, //标题
      content: row.content, //内容
      country: row.country, //归属国家
      sys_type: row.sys_type, //消息类型
      is_push: row.is_push, //是否推送
    };

    //发送用户
    this.tags = [];
    this.tagInput = "";
    this.tagInputMore = "";
    if (row.type == 1) {
      this.tags = [];
      for (var i: number = 0; i < row.uids.length; i++) {
        this.tags.push(String(row.uids[i]));
      }
    }

    //隐藏界面
    this.dialogShow = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //删除消息
    this.$confirm("确定删除消息 ?", "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //删除博主
        await delOfficialSystem({ id: row.id });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //------------------------------- 添加/编辑系统消息 -------------------------------
  //表单数据
  private form: any = {
    //表单数据
    type: 0, //发送
    title: "", //标题
    content: "", //内容
    uids: [], //用户ID列表
    country: "MY", //归属国家
    sys_type: 0, //消息类型
    is_push: 0, //是否推送
    id: undefined, //ID
  };

  //消息类型
  private sysTypeList: any[] = [
    { id: 0, name: "请选择消息类型" },
    { id: 1, name: "系统通知" },
    { id: 2, name: "违规警告" },
  ];

  //定义变量
  private tags: any[] = [];
  private tagInput: string = "";
  private checked: boolean = false;
  private tagInputMore: string = "";
  private dialogShow: boolean = false;
  private dialogLoading: boolean = false;

  //保存按钮
  private btneSave() {
    //异常消息
    if (this.form.sys_type == 0) return this.$message.error("请选择消息类型");
    if (this.form.type == 0) return this.$message.error("请选择发布对象");
    if (this.form.title == "") return this.$message.error("请输入标题");
    if (this.form.content == "") return this.$message.error("请输入内容");
    if (this.form.country == "") return this.$message.error("请选择归属国家");
    if (this.form.type == 1 && this.tags.length == 0) return this.$message.error("请添加用户ID");

    //数据赋值
    this.form.uids = [];
    if (this.form.type == 1) {
      for (var i: number = 0; i < this.tags.length; i++) {
        this.form.uids.push(Number(this.tags[i]));
      }
    } else {
      this.form.is_push = 0;
    }

    //处理添加
    if (this.isCreate()) {
      //处理添加消息
      this.handleAddMsg();
    }
    //处理更新
    else {
      //处理更新消息
      this.handleUpdataMsg();
    }
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;
  }

  //是否创建
  private isCreate(): boolean {
    return this.form.id === undefined;
  }

  //处理添加消息
  private async handleAddMsg() {
    //显示等待
    this.dialogLoading = true;

    //获取数据
    await addOfficialSystem(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }

  //添加ID
  private addTag(type: any): void {
    //定义变量
    let errorList: any = [];

    //批量添加
    if (type === "more") {
      //添加数据
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          //移仓处理
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());

          //数据赋值
          this.tags.push(item.trim());

          //清空输入框
          this.tagInputMore = "";
        });

      //显示提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //验证ID
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        //数据赋值
        this.tags.push(this.tagInput.trim());

        //清空输入框
        this.tagInput = "";
      } else {
        //显示提示
        this.$message.error("请输入正确形式的id");

        //清空输入框
        this.tagInput = "";
      }
    }
  }

  //处理更新消息
  private async handleUpdataMsg() {
    //显示等待
    this.dialogLoading = true;

    //获取数据
    await updateOfficialSystem(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }

  //类型切换
  private typeChange(val: any): void {
    //清空数据
    if (val == 0) {
      this.form.title = "";
      this.form.content = "";
    } else if (val == 1) {
      this.form.title = "系统通知";
      this.form.content = "";
    } else if (val == 2) {
      this.form.title = "违规行为警告";
      this.form.content = "尊敬的用户，您的言论或行为可能涉及违规。为了维护社区秩序和安全，如果再有涉及违规内容我们将会对您的账号进行封号处理！";
    }
  }

  //删除数据
  private removeTag(index: any): void {
    //数据赋值
    this.tags.splice(index, 1);
  }
}
