//本地导入
import request from "../request";

//添加公告
export const addOfficial = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/add",
  });

//删除公告
export const delOfficial = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/del",
  });

//获取公告列表
export const getOfficiallist = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/list",
  });

//编辑公告
export const editOfficial = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/edit",
  });

//获取系统通知列表
export const getOfficialSystemList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/sysmsg/list",
  });

//添加系统消息
export const addOfficialSystem = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/sysmsg/add",
  });

//修改系统消息
export const updateOfficialSystem = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/sysmsg/update",
  });

//修改系统消息
export const delOfficialSystem = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inbox/official/sysmsg/del",
  });
